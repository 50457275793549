.aiRecommendRoot {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  align-self: stretch;
  padding: 12px;
  overflow: hidden;
  border: 1px solid var(--blue-100-d-8-e-9-ff, #d8e9ff);
  border-radius: 4px;

  &::before {
    position: absolute;
    top: -101px;
    left: -141px;
    display: inline-block;
    width: 728px;
    height: 133px;
    content: " ";
    background: #f8eeff;
    filter: blur(23.049px);
    border-radius: 728px;
  }

  &::after {
    position: absolute;
    top: -21px;
    right: -43px;
    width: 506px;
    height: 52px;
    background: #f0f4ff;
    filter: blur(23.049px);
    border-radius: 506px;
  }

  .header {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    // margin-bottom: 12px;

    .text {
      font-weight: 600;
      letter-spacing: 0.36px;
      background: var(--gradient-01, linear-gradient(62deg, #8d71ff -3.95%, #197af7 77.79%));
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .aiRecommend {
    ul {
      margin-left: 20px;
    }
  }
}

.useItBtn {
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  height: 32px;
  padding: 12px 24px;
  color: #fff;
  cursor: pointer;
  background: var(--gradient-01, linear-gradient(62deg, #8d71ff -3.95%, #197af7 77.79%));
  border-radius: 24px;

  &:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%),
      linear-gradient(62deg, #8d71ff -3.95%, #197af7 77.79%);
  }

  &:focus {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
      linear-gradient(62deg, #8d71ff -3.95%, #197af7 77.79%);
  }
}

.aiIssue {
  display: flex;
  gap: 4px;
  align-items: flex-start;
  align-self: stretch;
  padding: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #515151;
  background: #fffbf6;
  border: 1px solid #ffe4bd;
  border-radius: 8px;

  /* stylelint-disable-next-line no-descending-specificity */
  ul {
    margin-left: 20px;
  }
}
