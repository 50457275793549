@import "styles/mixins.scss";
@import "styles/variable.scss";
$paddingLeftWidth: 600px;
$maxContentWidth: 1136px;
$contentWidth: 894px;

.profile {
  position: relative;
  background: #f7f8fa;

  .bg {
    position: absolute;
    width: 1366px;
    height: 768px;
    left: 0px;
    top: 50%;
    background: linear-gradient(
      166.11deg,
      #ffffff 18.61%,
      #9dedff 59.16%,
      #1052fb 100.91%,
      #1052fb 133%,
      rgba(16, 82, 251, 0.89) 186.95%
    );
    opacity: 0.6;
    filter: blur(154.5px);
    transform: translateZ(0) translateY(-50%);
  }

  .mainContainer {
    width: 960px;
    margin: 0 auto;
    display: flex;
    // flex-direction: column;
    padding-bottom: 30px;
  }

  .rightContainer {
    margin-top: 32px;
    flex: 1;
    background: #ffffff;
    border-radius: 8px;
    padding: 30px;
    position: relative;
    z-index: 2;
    width: 0;
  }

  :global {
    // .MuiInputLabel-shrink {
    //   font-size: 12px !important;
    // }
    .MuiOutlinedInput-input {
      font-size: 16px;
    }

    .css-130rmp2-MuiAutocomplete-root .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input {
      padding: 2px 4px 8px 0;
    }

    .MuiInput-root::before {
      border-bottom: 1px solid #e4e4e4;
    }

    .MuiInput-root:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #d7d7d7;
    }
  }

  p,
  ul,
  h3 {
    margin: 0;
  }
}

.main {
  position: relative;

  .header {
    width: 100%;
    height: 64px;
    background: #fff;
    line-height: 55px;
    position: relative;
    z-index: 2;

    .headerMain {
      display: flex;
      width: $maxContentWidth;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      color: white;
      height: 100%;

      .headerLogoImage {
        width: 124px;
        height: 32px;
      }

      a {
        display: inherit;
        color: white;
      }
    }
  }

  .btnList {
    display: flex;
    padding-top: 8px;
    flex-wrap: wrap;

    button {
      width: 346px;
      margin-right: 20px;
      background: #e7f1fb;
      border-radius: 10px;
      font-size: 18px;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      color: #353535;
      justify-content: center;
      height: 60px;
      transition: 0.2s;
      margin-bottom: 16px;
      cursor: pointer;
      border: 1.5px solid #e4e4e4;
      background: #fff;

      &:hover,
      &.active {
        border: 1.5px solid #136fd3;
        color: #2378e5;
        transition: 0.2s;
      }

      &:nth-of-type(even) {
        margin-right: 0;
      }
    }
  }

  .btnGroup {
    display: flex;
    border-left: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 0;

    button {
      flex: 1;
      border: none;
      margin: 0;
      border-top: 1px solid #e4e4e4;
      border-right: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      margin-right: 0;
      border-radius: unset;
      position: relative;
      font-size: 18px;
      font-weight: 400;
      background: #fff;
      color: #7d7d7d;
      height: 60px;
      cursor: pointer;
      padding: 0 10px;

      &:first-child {
        display: inline-flex;
        white-space: nowrap;
        align-items: center;
        justify-content: center;

        border-radius: 8px 0 0 8px;
      }

      &:last-child {
        border-radius: 0 8px 8px 0;
      }

      &:hover,
      &.active {
        color: #2378e5;
        transition: 0.2s;
      }

      &:first-child::after {
        border-radius: 8px 0 0 8px;
      }

      &:last-child::after {
        border-radius: 0 8px 8px 0;
      }

      &.active::after {
        content: " ";
        position: absolute;
        left: -1px;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
        z-index: 10;
        border: 1px solid #2378e5;
      }
    }
  }

  .work {
    margin-top: 48px;
    min-height: 300px;
    .header {
      margin-bottom: 26px;
    }

    .resumeList {
      margin-bottom: 8px;
    }

    .box {
      .headerInfo {
        line-height: 69px;
        font-size: 32px;
        line-height: 40px;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        color: #353535;
        font-weight: 700;
      }

      .body {
        margin-bottom: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 1px;
        color: #121212;
        // padding: 40px 0 20px;

        span {
          color: red;
          padding-right: 10px;
          position: relative;
          top: 4px;
        }
      }

      .upload {
        margin: 20px 0 8px 0;

        :global {
          .MuiLoadingButton-root {
            width: 345px;
            max-width: 100%;
          }
        }
      }

      .stepFieldDateItem {
        // margin-left: 20px;
        flex: 1;
        display: flex;
        gap: 16px;
        // max-width: 231px;
      }

      .workingSince,
      .stepFieldDateItem {
        :global {
          .MuiInputLabel-root {
            left: 0;
          }
        }
      }

      .workingSince {
        width: 345px;
      }

      .stepFieldToItem {
        input {
          padding: 10px 12px;
        }

        :global {
          .MuiInputLabel-root {
            left: 0;
          }
        }
      }
      .timeFormWrapper {
        max-width: 330px;
      }

      .pickBox {
        flex: 1;

        .stepFieldToItem {
          flex: 1;
          margin-left: 20px;
        }
      }

      .chooseSkill {
        p {
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 1px;
          color: #707070;
          line-height: 18px;
        }

        .labelBox {
          display: block;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
        }
      }

      .inputTag {
        .inputSkills {
          display: flex;
          flex-wrap: wrap;
        }

        input {
          height: 30px;
          width: 250px;
          max-width: 100%;
          margin-top: 14px;
          padding: 4px 0;
          border: none;
          outline: none;
          transition: 0.1s all linear;
          background: transparent;
        }
      }

      .stepFieldDate {
        display: flex;
        justify-content: space-between;
        flex: 1;
        flex-direction: column;
        gap: 16px;
      }

      .fillLater {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 1px;
        color: #136fd3;
        padding-top: 34px;
        cursor: pointer;
        display: inline-block;
      }

      .currency {
        width: 158px;
      }
    }

    .salaryBox {
      display: flex;
      flex: 1;
      justify-content: space-between;
    }

    .minSalary {
      margin-left: 20px;
      flex: 1;
    }
    .eduSelectWrapper {
      height: 43px;
      :global {
        .MuiOutlinedInput-root {
          height: 100%;
        }
        .MuiInputLabel-shrink {
          font-size: 1em !important;
        }
        .MuiInputLabel-outlined {
          font-size: 14px;
          transform: translate(14px, 13px) scale(1);
          &[data-shrink="true"] {
            transform: translate(14px, -9px) scale(0.75);
          }
        }
      }
    }
  }

  .stepMobile {
    display: flex;
    padding: 0 20px;

    li {
      margin-right: 10px;
      list-style: none;
      flex: 1;
      text-align: center;
      color: #c4ddfe;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: #136fd3;

        span {
          background: #136fd3;
        }
      }

      span {
        display: block;
        height: 6px;
        background: #c4ddfe;
        margin-bottom: 8px;
      }
    }
  }

  .skipModal {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 999;

    .bg {
      width: 100%;
      height: 100%;
      position: absolute;
      background: rgba(196, 196, 196, 0.5);
    }

    .modalContainer {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 740px;
      position: absolute;
      background: #fff;
      border-radius: 15px;
    }

    .modalHeader {
      height: 59px;
      background: #f9f9f9;
      border-radius: 15px 15px 0px 0px;
      display: flex;
      align-items: center;
      padding-left: 24px;
      font-weight: 700;
      font-size: 18px;
    }

    .modalBody {
      padding: 23px 48px 0 22px;

      .tips {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-bottom: 34px;
      }
    }

    .modalFooter {
      border-top: 1px solid #bcbcbc;
      height: 84px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 24px;
    }
  }

  .addSkils {
    padding: 10px 0;
    display: flex;

    input {
      flex: 1;
      height: 60px;
      border-radius: 8px;
      border: 1px solid #e4e4e4;
      margin-right: 20px;
      text-indent: 10px;
      font-size: 16px;
    }

    button {
      border-radius: 8px;
      background: #2378e5;
      box-shadow: 0px 7px 8px 0px #d8e5ff;
      width: 177px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      color: #fff;
      border: none;
      outline: none;
    }
  }

  .addSkillBtn {
    color: #515151;
    border: 1px dashed #b8b8b8;
    background: #f6f6f6;
    min-width: 60px;
    border-radius: 4px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    margin-top: 10px;
    cursor: pointer;
  }

  .step1SalaryRanges {
    display: flex;
  }

  :global {
    .MuiOutlinedInput-root {
      border-radius: 10px;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiStepConnector-horizontal {
      top: 16px;
    }

    .MuiChip-deleteIconOutlinedColorPrimary {
      path {
        color: #fff;
      }
    }

    .MuiStepLabel-root .MuiSvgIcon-root {
      width: 32px;
      height: 32px;
    }

    .MuiLoadingButton-root:hover {
      color: #fff;
    }

    .MuiLoadingButton-root.Mui-disabled {
      border: none;
    }

    .MuiStepLabel-label.Mui-completed,
    .MuiStepLabel-label.Mui-active,
    .MuiStepLabel-label {
      color: #4e5969;
      font-weight: 400;
    }

    .MuiStepIcon-root.Mui-completed {
      color: #2378e5;
    }
  }
}

.stepPC {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding-top: 8px;

  .step {
    width: 140px;

    .line,
    .line2 {
      height: 35px;
      width: 1px;
      margin: 0 auto;
      background: #c5dbf6;
    }

    .shadow {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      padding: 4px;
      margin: 0 auto;
      background: transparent;
    }

    p {
      margin: 0;
      color: #4e5969;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 150% */
      letter-spacing: 0.12px;
      padding: 10px 0;
      text-align: center;
    }

    .circle {
      width: 40px;
      height: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: flex;
      font-size: 20px;
      font-weight: 700;
      border-radius: 50%;
      background: #c5dbf6;
      color: #fff;
    }
  }

  .stepACtive,
  .stepFull {
    .shadow {
      background: rgba(58, 144, 255, 0.5);
    }

    .line,
    .line2 {
      background: #2378e5;
    }

    .circle {
      background: #2378e5;
    }
  }

  .stepACtive {
    .shadow {
      background: rgba(58, 144, 255, 0.5);
      animation: moveTo 2s ease infinite;
    }

    @keyframes moveTo {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }
  }
}

.titleTip {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #707070;
}

.skillList {
  margin: 20px 0 0 0;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;

  span {
    cursor: pointer;
    height: 60px;
    background: #e7f1fb;
    border-radius: 16px;
    line-height: 60px;
    padding: 0 20px;
    font-size: 18px;
    letter-spacing: 1px;
    display: inline-block;
    color: #353535;

    border-radius: 8px;
    border: 1px solid #e4e4e4;
    background: #fff;
  }
}

.syncHeader {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}
.syncDesc {
  color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4;
}

.tagWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  color: #fff;
  span {
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--main-color-blue-blue-012378-e-5, #2378e5);
    color: var(--Neutral-White, #fff);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    gap: 2px;
    &[data-action="click"] {
      cursor: pointer;
      padding: 0;
    }
  }
}

.footerWrapper {
  opacity: 1;
}
.footerArea {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 56px;
  button {
    border-radius: 4px;
    padding: 10px 24px;
    text-transform: capitalize;
    font-size: 14px;
    line-height: normal;
    height: 40px;
    min-width: 152px;
    border: none;

    &.skipBtn {
      color: #515151;
      background: var(--neutral-color-line-02-e-8-e-8-ea, #e8e8ea);
      box-shadow: none;
      &:hover {
        color: #515151;
        background-color: #b8b8b8;
      }
    }
    &:global(.MuiLoadingButton-loading) {
      color: transparent;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

.loadingRoot {
  height: 400px;
}
.normalSelect {
  :global {
    .MuiInputLabel-root {
      top: -6px;
      &[data-shrink="true"] {
        top: 0px;
      }
    }
    .MuiSelect-select {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 32px;
    }
  }
}

@import "./indexMobile.module.scss";
