.jobCard {
  width: 100%;
  min-height: 166px;
  position: relative;
  padding-right: 0;

  .closeButton {
    position: absolute;
    top: 8px;
    right: 10px;
  }

  .urgent {
    display: flex;
    padding: 1px 3px 2px 3px;
    justify-content: center;
    align-items: center;

    color: #fffefe;
    font-size: 10px;
    font-family: 'Product Sans';
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.075px;

    border-radius: 4px;
    background: linear-gradient(128deg, #f64a88 0%, #f56a6a 100%);
    box-shadow: 0px 4px 13px 0px rgba(255, 60, 60, 0.1);

    margin-right: 8px;
  }

  .container {
    width: calc(100%);
    height: 100%;
    overflow: hidden;
    position: relative;
    transition: box-shadow 0.3s ease-in;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #e5e6eb;
    background: #fff;

    &:hover {
      border: 1px solid #579bf5;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);

      .bottomContainer {
        &::before {
          background: linear-gradient(75deg, #dff6ff 0%, #fff 100%);
        }
      }
    }

    .topContainer {
      width: 100%;
      padding: 16px 13px 16px 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left {
        width: 476px;
        height: 100%;

        .titleContainer {
          width: 100%;
          height: 23px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          width: 100%;
          align-items: center;

          .remote {
            color: #FFFEFE;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 16.667px;
            /* 166.667% */
            letter-spacing: 0.075px;
            text-transform: capitalize;
            border-radius: 4px;
            padding: 1px 5px 2px 4px;
            background: linear-gradient(101deg, #FFA500 6.03%, #FFCD7E 92.76%);
            box-shadow: 0px 6.66667px 21.66667px 0px rgba(255, 60, 60, 0.10);
            margin-right: 8px;
          }

          .title {
            --color: #1d2129;
            color: var(--color);
            flex: 1;
            width: 0;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.128px;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              --color: #136fd3;
            }
          }
        }

        .salary {
          color: #2378e5;
          text-align: right;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0.113px;
          margin-top: 6px;
          width: fit-content;
          margin-right: 10px;
        }

        .labelContainer {
          margin-top: 6px;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: 30px;
          overflow: hidden;

          .label {
            display: inline-block;
            padding: 5px 10px;

            border-radius: 6px;
            background: #f7f8fa;
            margin-right: 8px;

            color: #4e5969;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 6px;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }


        }

        .recruiterContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 6px;
          width: 100%;

          .imgContainer {
            position: relative;
            overflow: visible;

            img {
              border: 1px solid #e5e6eb;
            }

            .isOnline {
              width: 9px;
              height: 9px;
              border-radius: 4.5px;
              border: #fff solid 1px;
              background: #0ebd5c;
              position: absolute;
              bottom: -2px;
              right: 4px;
            }
          }

          .recruiter {
            .info {
              max-width: 334px;
              padding: 2px 0px;
              margin-left: 4px;
              color: #4e5969;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              letter-spacing: 0.09px;
              height: 24px;
              line-height: 24px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.isHover {
                display: none;
              }
            }

            .button {
              position: relative;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: auto !important;
              // line-height: 25px;
              text-transform: capitalize;
              margin-left: 4px;
              margin-top: -1px;
              padding: 4px 16px;

              border-radius: 4px;
              background: #2378e5;
              box-shadow: 0px 4px 10px 0px rgba(35, 120, 229, 0.15);
              display: none;

              &::after {
                content: '';
                background: #2378e5;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 8px;
                left: -3px;
                transform: rotate(45deg);
              }

              &.isHover {
                display: flex;
                height: 25px !important;
                cursor: pointer;
              }

              :global {
                .MuiCircularProgress-root {
                  color: #ffffff;
                }
              }
            }

            // height: 21px;
          }

          .online {
            border-radius: 4px;
            background: #f2f9f3;
            padding: 7px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 25px;
            line-height: 25px;
            color: #4caf50;
            margin-left: 8px;
            font-size: 12px;

            &:before {
              content: '';
              background: #4caf50;
              border-radius: 3px;
              border: 1px solid #4caf50;
              width: 6px;
              height: 6px;
              margin-right: 4px;
            }

            :first-child {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              display: flex;
              align-items: center;
              letter-spacing: 0.0075em;
              color: #ffffff;
            }
          }
        }
      }

      .right {
        // background: #0EBD5C;
        height: 100%;
        width: 280px;

        .company {
          // height: 23px;
          display: flex;
          flex-direction: row;
          width: 280px;

          .logo {
            border-radius: 4px;
            overflow: hidden;
            margin-right: 12px;
            border: 1px solid #e5e6eb;
          }

          .labelContainer {
            flex: 1;
            margin-top: -2px;

            .name {
              color: #1d2129;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.32px;
              width: 206px;
              display: -webkit-box;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;

              &:hover {
                color: #136fd3;
              }
            }

            .componylabels {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: flex-start;
              margin-top: 8px;
              // max-height: 62px;
              overflow: hidden;

              .label {
                display: flex;
                padding: 5px 10px;
                align-items: flex-start;
                border-radius: 6px;
                background: #f7f8fa;
                color: #4e5969;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                margin-right: 8px;
                margin-bottom: 8px;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }
          }
        }
      }
    }

    .bottomContainer {
      height: 38px;
      width: 100%;
      position: relative;
      border-radius: 10px 0px 10px 0px;

      &::before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 1;
        z-index: 1;
        background: linear-gradient(75deg, #f4faff 0%, #fff 100%);
        // background: linear-gradient(90deg, #F2F9FF 0%, #FFFFFF 100%);
      }

      padding-left: 24px;
      padding-right: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .skills {
        width: 478px;
        height: 15px;

        color: #4e5969;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.09px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 10;
      }

      .benefits {
        height: 15px;
        width: 280px;
        margin-left: 48px;

        color: #4e5969;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0.09px;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        z-index: 10;
      }
    }
  }

  .popupDetail {
    // --opacity:1;
    position: absolute;
    top: 0px;
    right: 30px;
    width: 380px;
    // height: 395px;
    background: #fff;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 10px 10px 10px #ddd;
    // opacity: var(--opacity);
    // transition: opacity 1 0.3s;
    // box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    z-index: 21;

    &.hide {
      display: none;
      // --opacity: 0;
    }

    .popTop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 115px;
      overflow: hidden;
      padding: 13px 16px;
      background: linear-gradient(354deg, #FFF 0%, #B7DBFF 100%);

      &Left {
        flex: 1;

        .title {
          font-family: 'Product Sans';
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 23px;
          align-items: center;
          letter-spacing: 0.02em;
          color: #353535;
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .secondLabel {
          font-family: 'Product Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.0075em;
          color: #707070;
          width: 260px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: 8px;
        }

        .save {
          background: transparent;
          box-shadow: none;
          border: 1px solid #136fd3;
          width: 126px;
          height: 30px;
          border-radius: 4px;
          color: #136fd3;
          margin-top: 15px;
        }
      }

      &Right {
        // flex: 0;
        width: 72px;
        height: 93px;
        padding: 6px 0px;
        background: #fff;

        font-family: 'Product Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        text-align: center;
        letter-spacing: 0.0075em;
        color: #136fd3;
      }
    }

    .popContent {
      padding: 14px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .desTitle {
        align-self: flex-start;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.0075em;
        color: #353535;
      }

      .detail::-webkit-scrollbar {
        width: 5px;
      }

      .detail::-webkit-scrollbar-thumb {
        background-color: #e4e4e4;
        border-radius: 5px;
      }

      .detail::-webkit-scrollbar-track {
        background: white;
        border-radius: 5px;
      }

      .detail {
        align-self: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #353535;
        max-height: 213px;
        overflow: auto;
        margin-top: 8px;
        width: 100%;
      }
    }
  }
}