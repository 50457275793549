.mobileSelectForCountryWrapper {
  :global {
    .MuiPaper-root {
      border-radius: 16px 16px 0 0;
    }
  }
}
.mobileSelectForCountry {
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;

  .mobileSelectForCountryHeader {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    color: #121212;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  .mobileSelectForCountryContent {
    flex: 1;
    overflow: auto;
  }
  .mobileSelectForCountry_sectionHeader {
    display: flex;
    padding: 16px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    &[data-expand="false"] {
      & > svg {
        transform: rotate(180deg) !important;
      }
    }
  }

  .mobileSelectForCountry_sectionContent {
    background-color: #fcfcfc;
    overflow: hidden;
    height: 0px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &[data-expand="true"] {
      height: auto;
      padding: 12px 20px;
    }
  }

  .mobileSelectForCountry_label {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
