/* stylelint-disable selector-class-pattern */
.baseModalRoot {
  :global {
    .ant-modal-content {
      padding: 32px;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    color: var(--main-color-black-black-01121212, #121212);
  }

  .subTitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--neutral-color-text-047-d-7-d-7-d, #7d7d7d);
  }
}

.preferenceGroup {
  display: flex;
  flex-direction: column;
  gap: 0;

  .preferenceItem {
    width: 100%;
    padding: 16px 0;
    border-bottom: 1px solid var(--neutral-color-line-01-f-6-f-6-f-6, #f6f6f6);
  }
}

.preferenceTag {
  padding: 1.5px 5px 2px;
  font-size: 10px;
  font-weight: 600;
  color: #2378e5;
  background: linear-gradient(62deg, rgba(141, 113, 255, 0.1) -3.95%, rgba(25, 122, 247, 0.1) 77.79%), #fff;
  border-radius: 8px 8px 8px 0;
}
