@include msd {
    .profile {
        .bg {
            display: none;
        }

        .mainContainer {
            width: 100%;
            display: block;
        }
        .rightContainer {
            width: 100%;
            box-shadow: none;
            padding: 20px;
            padding-bottom: 100px;
            margin-top: 0px;
        }
    }

    .main {
        background: #fff;
        // padding-bottom: 60px;

        .addSkils {
            button {
                width: 110px;
            }
        }

        .header {
            margin-bottom: 8px;
        }

        .header {
            .headerMain {
                width: 100%;
                padding: 0 20px;
            }
        }

        .btnList {
            button {
                width: 100%;
            }
        }

        .work {
            margin-top: 32px;
            .workContainer {
                width: 100%;
            }

            .btnList {
                button {
                    margin-right: 0;
                }
            }

            .box {
                width: 100%;
                box-shadow: none;
                border-radius: 0;
                margin-top: 0;

                .workingSince {
                    width: 100%;
                }

                .body {
                    .stepFieldDate {
                        display: block;
                        height: auto;
                        margin-top: 20px;
                    }
                }

                // .stepFieldDateItem {
                //     margin-left: 0;
                // }

                .stepFieldBody {
                    display: block;

                    // .workingPeriod {
                    //     display: block;
                    //     height: auto;
                    //     position: relative;
                    //     top: -10px;
                    // }

                    .pickBox {
                        .stepFieldToItem {
                            width: 100%;
                            max-width: 100%;
                        }

                        .stepFieldToItem:first-child {
                            margin-left: 0;
                        }
                    }
                }
                .timeFormWrapper {
                    max-width: 100%;
                }
                .stepFieldDate {
                    display: block;

                    .stepFieldDateItem {
                        margin-bottom: 16px;
                        max-width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .step1SalaryRanges {
                    display: block;
                }

                .currency {
                    width: 100%;
                }

                .salaryBox {
                    display: block;

                    .minSalary {
                        margin-right: 0;
                        margin-left: 0;
                        margin-top: 20px;
                    }
                }
            }
        }

        .skipModal {
            .modalContainer {
                width: 100%;
                left: 0;
                transform: translate(0, 0);
                bottom: 0;
                top: unset;
            }
        }
    }
    .footerWrapper {
        display: none;
    }
    .footerArea {
        flex-direction: column-reverse;
        position: fixed;
        bottom: 0;
        background-color: #fff;
        padding: 15px 20px;
        padding-bottom: 28px;
        width: 100%;
        box-sizing: border-box;
        left: 0;
        z-index: 10;
        // display: block;
        gap: 0;

        button {
            width: 100%;
            border-radius: 8px;
            &:first-child {
                background-color: #fff !important;
                // border-radius: 8px;
                border: 1px solid #136fd3;
                color: #2378e5;
                &:hover {
                    color: #2378e5;
                }
            }
            &:nth-child(2) {
                margin-bottom: 12px;
            }
        }
    }
}
.syncDesc {
    margin-bottom: 8px;
}
