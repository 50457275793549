@import 'styles/mixins';

.main {
  position: relative;
  min-height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #f7f8fa;
}

// Common header
.header {
  width: 100%;
  background: #fff;
  height: 58px;
  position: relative;
  z-index: 2;
  padding: 0 60px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @include sm {
    padding: 0 20px;
  }

  .logo {
    width: 108px;
    height: 30px;

    svg {
      width: 100%;
      height: 100%;
    }

    @include sm {
      width: 80px;
    }
  }

  .link {
    display: flex;
    height: 36px;
    padding: 0 17px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    border: 1px solid #e8e8ea;

    color: #121212;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .button {
    display: flex;
    outline: none;
    border: 0;
    background: transparent;
    box-shadow: none;
  }
}

.container {
  position: relative;
  height: 100%;
  flex: 1;
}

.safeInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 56px;

  color: #86909c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  a {
    color: #86909c;
    text-decoration-line: underline;
  }

  @include sm {
    padding: 20px;
    margin-bottom: 0;
  }

  &_icon {
    margin-right: 4px;
    flex-shrink: 0;

    @include sm {
      margin-right: 8px;
    }
  }
}

// footer layout
.footer {
  background: #f7f8fa;
  margin-bottom: 48px;

  @include sm {
    margin: 0;
  }

  &_container {
    border-top: 1px solid #e8e8ea;

    @include sm {
      border: 0;
    }
  }

  &_content {
    max-width: 1136px;
    margin: 0 auto;
    color: #7d7d7d;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    @include sm {
      padding: 24px 20px;
      color: #b8b8b8;
    }
  }

  &_copyright_text {
    text-align: center;
    font-size: 14px;
    padding: 20px 0;

    @include sm {
      padding: 0;
    }
  }

  &_change_lan {
    height: 58px;
    position: relative;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include sm {
      display: none;
    }
  }

  &_current_lan {
    color: #b8b8b8;
    margin: 0 10px;
  }

  &_choose_lan {
    position: absolute;
    right: -25px;
    bottom: 50px;
    z-index: 100;
    display: flex;
    width: 147px;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    transition: visibility 0.2s ease-in-out;
  }

  &_choose_item {
    display: flex;
    height: 36px;
    padding: 0px 8px;
    align-items: center;
    align-content: center;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    color: #121212;
    font-size: 14px;

    &:hover {
      background-color: #f5f7fb;
    }
  }
}

.jobFunction {
  &_selector {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 30px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    padding: 0 34px 0 14px;
    position: relative;
    background-color: #ffffff;
    cursor: pointer;

    &:hover {
      border: 1px solid #4096ff;
    }
  }

  &_placeholder {
    color: #7d7d7d;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &_value {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_arrow {
    position: absolute;
    right: 10px;
    top: 11px;
  }
}

.loadingButton {
  &_spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    min-width: 30px;
  }

  &_disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &_icon {
    display: inline-block;
    animation: animation-spin 1.4s linear infinite;
  }
}

@keyframes animation-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}